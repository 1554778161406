import waitForPolyfills from './components/polyfill/polyfill-helper';
import 'fslightbox';

window.polyfill_uri = '/assets/dist/js/polyfills.js';

function main() {
    // Menu button
    let menuBtn = document.querySelector('.menu-btn');
    menuBtn.addEventListener('click', () => {
        let body = document.querySelector('body');
        body.classList.toggle('nav-is-active');
    });

    // Window scroll class
    window.addEventListener('scroll', () => {
        let bodyScrollTop = document.querySelector('body').scrollTop;
        let htmlScrollTop = document.querySelector('html').scrollTop;
        let scrollTop = Math.max(bodyScrollTop, htmlScrollTop);

        let body = document.querySelector('body');

        if (scrollTop > 50 && body.classList.contains('has-scrolled') == false) {
            body.classList.add('has-scrolled');
            return;
        }

        if (scrollTop < 50 && body.classList.contains('has-scrolled')) {
            body.classList.remove('has-scrolled');
            return;
        }

    })

    // Highlight Nav Link
    let navItems = document.querySelectorAll('header .menu a');

    for (let item of navItems) {
        let href = item.getAttribute('href');

        if (window.location.href.indexOf(href) >= 0) {
            item.classList.add('is-active');
        }
    }
}

waitForPolyfills(main);