/**
 * Check for needed function and load polyfills in case
 */
function waitForPolyfills(entry) {
    if ('assign' in Object) {
        return entry();
    }

    console.log('Load Polyfills', window.polyfill_uri);
    return loadScript(window.polyfill_uri, entry);
}

/**
 * Load a script ressource and execute callback afterwards
 */
function loadScript(src, callback) {
    var js = document.createElement('script');
    js.src = src;
    js.onload = function() {
        callback(callback);
    };

    js.onerror = function() {
        callback(callback);
    };

    document.head.appendChild(js);
}

export default waitForPolyfills;